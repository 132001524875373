import { Link } from 'gatsby';
import CustomProfileDisplay from "../../../../src/components/CustomProfileDisplay";
import MCName from "../../../../src/components/subtags/MCName";
import MCObject from "../../../../src/components/subtags/MCObject";
import MCPosessive from "../../../../src/components/subtags/MCPosessive";
import MCPosessivePronoun from "../../../../src/components/subtags/MCPosessivePronoun";
import MCReflexive from "../../../../src/components/subtags/MCReflexive";
import MCSubject from "../../../../src/components/subtags/MCSubject";
import TName from "../../../../src/components/subtags/TName";
import TObject from "../../../../src/components/subtags/TObject";
import TPosessive from "../../../../src/components/subtags/TPosessive";
import TPosessivePronoun from "../../../../src/components/subtags/TPosessivePronoun";
import TReflexive from "../../../../src/components/subtags/TReflexive";
import TSubject from "../../../../src/components/subtags/TSubject";
import * as React from 'react';
export default {
  Link,
  CustomProfileDisplay,
  MCName,
  MCObject,
  MCPosessive,
  MCPosessivePronoun,
  MCReflexive,
  MCSubject,
  TName,
  TObject,
  TPosessive,
  TPosessivePronoun,
  TReflexive,
  TSubject,
  React
};