import React, { useContext } from 'react'
import { CustomContext } from '../Layout'

const SubTagPronoun = (props) => {
  const customContext = useContext(CustomContext)
  let gender = 'Initialised'

  // on first load there may not be active profile data so use placeholder
  if (customContext.activeProfileName) {
    gender = customContext.activeProfileName[props.field]
  } else {
    gender = '***'
  }

  // derive the appropriate pronoun
  const pronoun = props.pronoun
  let pronounReturn = props.pronoun
  const cap = props.cap

  switch (pronoun) {
    case 'subject':
      switch (gender) {
        case 'male':
          pronounReturn = 'he'
          break
        case 'female':
          pronounReturn = 'she'
          break
        default:
          pronounReturn = 'they'
      }
      break
    case 'object':
      switch (gender) {
        case 'male':
          pronounReturn = 'him'
          break
        case 'female':
          pronounReturn = 'her'
          break
        default:
          pronounReturn = 'them'
      }
      break
    case 'posessive':
      switch (gender) {
        case 'male':
          pronounReturn = 'his'
          break
        case 'female':
          pronounReturn = 'her'
          break
        default:
          pronounReturn = 'their'
      }
      break
    case 'posessivePronoun':
      switch (gender) {
        case 'male':
          pronounReturn = 'his'
          break
        case 'female':
          pronounReturn = 'hers'
          break
        default:
          pronounReturn = 'theirs'
      }
      break
    case 'reflexive':
      switch (gender) {
        case 'male':
          pronounReturn = 'himself'
          break
        case 'female':
          pronounReturn = 'herself'
          break
        default:
          pronounReturn = 'themself'
      }
      break
    default:
      pronounReturn =
        'oh dear something went wrong, clicking a profile should fix it.'
  }

  if (cap && pronounReturn) {
    pronounReturn =
      pronounReturn.charAt(0).toUpperCase() + pronounReturn.slice(1)
  }

  return <>{pronounReturn}</>
}

export default SubTagPronoun
