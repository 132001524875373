import React, { useState, useContext } from 'react'
import CustomProfileForm from '../components/CustomProfileForm'
import { CustomContext } from './Layout'

// This component displays the custom profiles that allow story customisation
// It has an add profile button that invokes the custom profile form in a modal
// Newly created profiles default as the active profile
// It displays a list of profiles and lets users click to activate (if > 1 profile)
// It also allows for deletion of the profiles

const CustomProfileDisplay = () => {
  const [dummy, setDummy] = useState(true) // toggle to force re-render must be boolean for state to change each time
  const [modalIsOpen, setModalIsOpen] = useState(false) // toggles the modal state
  const customContext = useContext(CustomContext)

  function toggleModal() {
    // toggle the state of the modal passed down from parent
    setModalIsOpen(!modalIsOpen)
  }

  function setActive(index) {
    // click on a ui button to select the custom profile
    // to populate the story
    // retrieve the profile the user selects from localStorage
    // and convert to a useful object
    const newActive = customContext.allProfilesName[index]
    customContext.setActive(newActive)

    customContext.setActiveIndex(index)
  }

  function deleteProfile(index) {
    // delete the custom profile the user clicks on
    let inputArr = customContext.allProfilesName

    // strip deleted row from inputArr
    let outputArr = inputArr.splice(index, 1)

    // check if deleted row is also the active profile, if so reset active index
    if (index == customContext.activeDisplayIndexName) {
      customContext.setActiveIndex(-1)
    }

    // adjust activeDisplayIndex when the record deleted is lower in the array
    if (index < customContext.activeDisplayIndexName) {
      customContext.setActiveIndex(customContext.activeDisplayIndexName - 1)
    }

    // save allProfiles with deleted row removed
    if (inputArr.length >= 0) {
      customContext.deleteProfile(inputArr)
      // toggle to force component refresh to avoid delay
      setDummy(!dummy)
    }
  }

  return (
    <div className="box has-background-white-ter">
      <h3 className="title is-size-5 pt-0 has-text-weight-bold is-bold-light">
        CUSTOM CHARACTERS
      </h3>
      <p className="block">Who are the stars of today&apos;s story?</p>
      <div className="field block">
        {!customContext.allProfilesName
          ? ''
          : customContext.allProfilesName.map((profile, index) => (
              <div key={index} className="block">
                <span
                  className={`button is-link ${
                    customContext.activeDisplayIndexName == index
                      ? ''
                      : 'is-light'
                  }`}
                  value={profile}
                  onClick={() => setActive(index)}
                >
                  {profile.mainCharacterName + ' & ' + profile.teddyName}
                  &nbsp;
                  <button
                    className="delete"
                    onClick={(e) => {
                      e.stopPropagation()
                      deleteProfile(index)
                    }}
                  ></button>
                </span>
              </div>
            ))}
      </div>
      <br />
      <button className="button is-success block" onClick={toggleModal}>
        CREATE NEW CHARACTERS
      </button>
      <div className={`modal ${modalIsOpen ? 'is-active' : ''}`}>
        <div className="modal-background"></div>
        <div className="modal-content">
          <CustomProfileForm value={modalIsOpen} onChange={toggleModal} />
          <button
            className="modal-close is-large"
            aria-label="close"
            onClick={toggleModal}
          >
            Close Me!
          </button>
        </div>
      </div>
    </div>
  )
}

export default CustomProfileDisplay
