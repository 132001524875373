import React from 'react'
import SubTagName from './SubTagName'

const MCName = (props) => {
  // capitalise the first letter
  // convention for name would usually be capitalise but
  // assuming that if the reader enters a lower case they
  // want it in lower case so only expect to set if at start
  // of sentence
  const cap = props.cap
  // return the posessive form ie 's
  const posessive = props.posessive
  return (
    <>
      &zwnj; <SubTagName field="mainCharacterName" posessive={posessive} cap={cap} />
    </>
  )
}

export default MCName
