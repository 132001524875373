import React, { useContext } from 'react'
import { CustomContext } from '../Layout'

const SubTagName = (props) => {
  const customContext = useContext(CustomContext)
  let displayVar = ''

  // on first load there may not be active profile data so use placeholder
  if (customContext.activeProfileName) {
    displayVar = customContext.activeProfileName[props.field]
  } else {
    displayVar = '***'
  }

  // handle the other name variants
  const cap = props.cap
  const posessive = props.posessive

  if (cap && displayVar) {
    displayVar = displayVar.charAt(0).toUpperCase() + displayVar.slice(1)
  }

  if (posessive && displayVar) {
    displayVar = displayVar + "'s"
  }

  if (cap && posessive && displayVar) {
    displayVar = (displayVar.charAt(0).toUpperCase() + displayVar.slice(1)) + "'s"
  }

  return <>{displayVar}</>
}

export default SubTagName
