import React, { useState, createContext, useEffect } from 'react'
import Navbar from '../components/Navbar'
import './all.sass'
import useSiteMetadata from './SiteMetadata'

// initiate  new context with array of empty object and empty function
export const CustomContext = createContext()

const TemplateWrapper = ({ children }) => {
  // read any profiles persisted in localstorage
  const localStateActiveProfile =
    typeof window !== `undefined`
      ? JSON.parse(localStorage.getItem('teddyStoryActiveProfile'))
      : {}
  const localStateProfilesRaw =
    typeof window !== `undefined`
      ? JSON.parse(localStorage.getItem('teddyStoryProfiles'))
      : []
  const localStateActiveIndex =
    typeof window !== `undefined`
      ? JSON.parse(localStorage.getItem('teddyStoryActiveProfileIndex'))
      : 0

  // initialise global state with persisted profiles (if any)
  const [activeProfile, setActiveProfile] = useState(localStateActiveProfile)
  const [allProfiles, setAllProfiles] = useState(localStateProfilesRaw)
  const [activeDisplayIndex, setActiveDisplayIndex] = useState(
    localStateActiveIndex
  )

  // useEffect statement to handle persistence in localStorage
  // This useEffect must be first to persist state through refresh
  useEffect(() => {
    setActiveProfile(
      JSON.parse(localStorage.getItem('teddyStoryActiveProfile'))
    )
    setAllProfiles(JSON.parse(localStorage.getItem('teddyStoryProfiles')))
  }, [])

  useEffect(() => {
    localStorage.setItem('teddyStoryProfiles', JSON.stringify(allProfiles))
  }, [allProfiles])

  useEffect(() => {
    localStorage.setItem(
      'teddyStoryActiveProfile',
      JSON.stringify(activeProfile)
    ) //write the profile record
  }, [activeProfile])

  useEffect(() => {
    localStorage.setItem(
      'teddyStoryActiveProfileIndex',
      JSON.stringify(activeDisplayIndex)
    ) //write the profile record
  }, [activeDisplayIndex])

  // the active profile will customise the story substitution tags
  const setActive = (activeObj) => {
    setActiveProfile(activeObj)
  }

  // the index of the active profile in allProfiles for setting className to highlight
  const setActiveIndex = (index) => {
    setActiveDisplayIndex(index)
  }

  // all profiles the reader has entered
  const setAll = (allObj) => {
    // prevent ... spread on null allProfiles
    // required where no persisted profiles
    allProfiles
      ? setAllProfiles([...allProfiles, allObj])
      : setAllProfiles([allObj])
  }

  // all profiles the reader has entered
  const deleteProfile = (inputArr) => {
    // prevent ... spread on null allProfiles
    // required where no persisted profiles
    setAllProfiles([...inputArr])
  }

  // the state object to be passed down with update functions
  const customProfiles = {
    activeProfileName: activeProfile,
    allProfilesName: allProfiles,
    activeDisplayIndexName: activeDisplayIndex,
    setActive,
    setAll,
    setActiveIndex,
    deleteProfile,
  }

  const { title, description, keywords } = useSiteMetadata()
  return (
    <CustomContext.Provider value={customProfiles}>
      <Navbar />
      <main>{children}</main>
    </CustomContext.Provider>
  )
}

export default TemplateWrapper
