import React, { useState, useRef, useEffect, useContext } from 'react'
import { Formik, Form, useField } from 'formik'
import * as Yup from 'yup'
import { CustomContext } from './Layout'
import { v4 as uuidv4 } from 'uuid'

const FormTextInput = ({ label, ...props }) => {
  const [field, meta] = useField(props)
  return (
    <div className="block">
      <label htmlFor={props.id || props.name}>{label}</label>
      <input className="text-input" {...field} {...props} />
      {meta.touched && meta.error ? (
        <div className="has-text-danger-dark">{meta.error}</div>
      ) : null}
    </div>
  )
}

const FormSelect = ({ label, ...props }) => {
  const [field, meta] = useField(props)
  return (
    <div className="block">
      <label htmlFor={props.id || props.name}>{label}</label>
      <select {...field} {...props} />
      {meta.touched && meta.error ? (
        <p className="has-text-danger-dark">{meta.error}</p>
      ) : null}
    </div>
  )
}

// End exports of custom story attributes

const CustomProfileForm = (props) => {
  const [profiles, setProfiles] = useState([]) // state is an array
  const [activeProfile, setActiveProfile] = useState({})
  const [dummy, setDummy] = useState(true) // toggle to force re-render must be boolean for state to change each time
  const customContext = useContext(CustomContext) // use context from Layout wrapped around root element
  const firstUpdate = useRef(true)

  // close the modal in the parent component
  function closeModal() {
    props.onChange()
  }

  const addToProfiles = (profile) => {
    customContext.setAll(profile)
  }

  return (
    <div className="box">
      <h3 className="title is-size-5 pt-0 has-text-weight-bold is-bold-light">
        CREATE YOUR CHARACTERS
      </h3>
      <Formik
        initialValues={{
          mainCharacterName: '',
          mainCharacterGender: '',
          teddyName: '',
          teddyGender: '',
        }}
        validationSchema={Yup.object({
          mainCharacterName: Yup.string()
            .max(100, 'The main character name must be 100 letters or less')
            .required('Who is the main character?'),
          mainCharacterGender: Yup.string()
            .oneOf(
              ['female', 'male', 'neutral'],
              'Please choose a gender for the main character.'
            )
            .required('Please choose a gender for the main character.'),
          teddyName: Yup.string()
            .max(100, 'Name must be 100 characters or less')
            .required('An adventurous teddy needs a name!'),
          teddyGender: Yup.string()
            .oneOf(
              ['female', 'male', 'neutral'],
              'Please choose a gender for teddy.'
            )
            .required('Please choose a gender for teddy.'),
        })}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setTimeout(() => {
            //const json = JSON.stringify(values, null, 0);
            let newValueObject = values
            let newValueObjectId = { id: uuidv4() }
            newValueObject = { ...newValueObjectId, ...newValueObject }

            customContext.setActive(newValueObject)
            addToProfiles(newValueObject)
            // trap for no profiles on first entry to form
            let initialLength =
              customContext.allProfilesName === null
                ? 0
                : customContext.allProfilesName.length
            customContext.setActiveIndex(initialLength)

            setSubmitting(false)
            closeModal()
            resetForm({ values: '' })
          }, 400)
        }}
      >
        <Form>
          <FormTextInput
            label="Main Character Name"
            name="mainCharacterName"
            type="text"
            className="input"
          />
          <FormSelect name="mainCharacterGender" className="select">
            <option value="">Select the main character&apos;s gender</option>
            <option value="female">Female</option>
            <option value="male">Male</option>
            <option value="neutral">Neutral</option>
          </FormSelect>
          <FormTextInput
            label="Teddy Name"
            name="teddyName"
            type="text"
            className="input"
          />
          <FormSelect name="teddyGender" className="select">
            <option value="">Select the teddy&apos;s gender</option>
            <option value="female">Female</option>
            <option value="male">Male</option>
            <option value="neutral">Neutral</option>
          </FormSelect>
          <button type="submit" className="button block is-success">
            CREATE
          </button>
          &nbsp;
          <input
            value="CANCEL"
            type="reset"
            className="button block"
            onClick={() => closeModal()}
          />
        </Form>
      </Formik>
    </div>
  )
}
export default CustomProfileForm
