import React from 'react'
import SubTagPronoun from './SubTagPronoun'

const MCPosessive = (props) => {
  // capitalise the first letter
  // convention for name would usually be capitalise but
  // assuming that if the reader enters a lower case they
  // want it in lower case so only expect to set if at start
  // of sentence
  const cap = props.cap
  // which pronoun do we need
  const pronoun = 'posessive'
  return (
    <>
      <SubTagPronoun field="mainCharacterGender" pronoun={pronoun} cap={cap} />
    </>
  )
}

export default MCPosessive
